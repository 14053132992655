import axios from "axios";
const SERVER_URL = process.env.VUE_APP_SERVER_URL;
const VUE_APP_GOOGLE_MAPS_URL = process.env.VUE_APP_GOOGLE_MAPS_URL;
const VUE_APP_COUNTRIRES_API = process.env.VUE_APP_COUNTRIRES_API;
const BASE_HEADERS = "";
const validStatuses = [200, 201, 400, 204, 403];
const CryptoJS = require("crypto-js");
const cryptSeceret = '#$%+' + process.env.VUE_APP_ACCESS_TOKEN_SECRET + '#$%+';

export class ApiHelper {
  constructor() { };



  post(uri, data) {
    let orgNumber = Vue.prototype.$localStorage.getItem('orgNum');
    let url = ''
    if (uri.includes('report') && (!uri.includes('dashboard/download/report'))) {
      url = uri + `&orgNumber=${orgNumber}`
    } else {
      url = uri;
    }
    axios.defaults.timeout = 180000;
    let encryptedData = {}
    if (data) {
      encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), cryptSeceret).toString();
    }
    const queryString = url.split('?')[1];
    url = url.split('?')[0];
    url = this.getEncryptedUrl(url, queryString);
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);
    return axios
      .post(SERVER_URL + url, { data: encryptedData }, {
        headers: this.getHeaders(),
        withCredentials: true
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  get(uri, params) {
    let orgNumber = Vue.prototype.$localStorage.getItem('orgNum');
    let url = '';
    let isShowLoader = false;
    if (uri.includes('report') && (!uri.includes('dashboard/generate/report') && !uri.includes('dashboard/status/report') && !uri.includes('dashboard/get/report'))) {
      url = uri + `&orgNumber=${orgNumber}`
      isShowLoader = true;
    } else {
      url = uri;
      isShowLoader = false;
    }


    // if(!params){
    //   params = {}
    //  }
    // let urlList = url.split("?")
    // if(urlList && urlList.length > 1){
    //  let query = CryptoJS.AES.encrypt(JSON.stringify(urlList[1]), cryptSeceret).toString();
    //  url = urlList[0]
    //   params['data'] = query
    // }
    const config = {
      params: {},
      headers: this.getHeaders(),
      withCredentials: true
    }
    axios.defaults.timeout = 180000;
    if (params && params.range) {
      url = url + `&range=${params.range[0]}&range=${params.range[1]}`
    }
    if (params && params.query) {
      url = url + `&query=${params.query}`
    }
    const queryString = url.split('?')[1];
    url = url.split('?')[0];
    if (isShowLoader) {
      Vue.set(Vue.prototype, '$globalVar', true);
    }
    apiCallCount = apiCallCount + 1;
    url = this.getEncryptedUrl(url, queryString);

    return axios
      .get(SERVER_URL + url, config)
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  encryptParamValues(params) {
    const encryptedParams = {};
    for (const [key, value] of Object.entries(params)) {
      const ciphertext = CryptoJS.AES.encrypt(value.toString(), cryptSeceret).toString();
      encryptedParams[key] = ciphertext;
    }
    return encryptedParams;
  }
  encryptQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    const encryptedParams = new URLSearchParams();
    let rangeList = []
    params.forEach((value, key) => {
      if (key === 'range') {
        rangeList.push(value)
      } else {
        encryptedParams.set(key, this.encryptValue(value));
      }
    });
    encryptedParams.set('range', this.encryptValue(rangeList));
    return encryptedParams.toString();
  };
  encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value.toString(), cryptSeceret).toString();
  };

  getEncryptedUrl = (url, queryString) => {
    const encryptedQueryString = this.encryptQueryString(queryString);
    return `${url}?${encryptedQueryString}`;
  };
  upload(uri, data) {
    let encryptedData = {}
    if (data) {
      encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), cryptSeceret).toString();
    }
    const queryString = uri.split('?')[1];
    uri = uri.split('?')[0];
    uri = this.getEncryptedUrl(uri, queryString);
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);
    return axios
      .post(SERVER_URL + uri, { data: encryptedData }, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  getAddress(uri) {
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);
    return axios
      .get(VUE_APP_GOOGLE_MAPS_URL + uri)
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  getCountryList() {
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);
    return axios
      .get(VUE_APP_COUNTRIRES_API)
      .then(response => response.data)
      .catch(err => { return Promise.reject(err) });
  }
  update(uri, data) {
    let encryptedData = {}
    if (data) {
      encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), cryptSeceret).toString();
    }
    const queryString = uri.split('?')[1];
    uri = uri.split('?')[0];
    uri = this.getEncryptedUrl(uri, queryString);
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);



    return axios.put(SERVER_URL + uri,
      { data: encryptedData }, {
        headers: this.getHeaders(),
        withCredentials: true
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  remove(uri) {
    const queryString = uri.split('?')[1];
    uri = uri.split('?')[0];
    uri = this.getEncryptedUrl(uri, queryString);
    apiCallCount = apiCallCount + 1;
    Vue.set(Vue.prototype, '$globalVar', true);

    return axios
      .delete(SERVER_URL + uri, {
        headers: this.getHeaders(),
        withCredentials: true
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }
  getHeaders(multipart = false) {
    let defaultHeaders = BASE_HEADERS;
    if (multipart) {
      defaultHeaders = {};
    }
    if (Vue.prototype.$localStorage.getItem("accessToken")) {
      let token = Vue.prototype.$localStorage.getItem("accessToken")
      const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(token), cryptSeceret).toString();
      defaultHeaders = {
        // Authorization: "Bearer " + encryptedToken,
        Authorization: null,
        ...defaultHeaders
      };
    } else {
      defaultHeaders = {
        Authorization: null,
        ...defaultHeaders
      };
    }
    return defaultHeaders;
  }
  /*
   * Wraps error responses from the API
   * and returns `Promise.reject` with error
   */
  checkResponse(response) {
    apiCallCount = apiCallCount - 1;
    if (apiCallCount == 0) {
      Vue.set(Vue.prototype, '$globalVar', false);
    }

    if (validStatuses.includes(response.status)) {
      let bodyBytes = CryptoJS.AES.decrypt(response.data, cryptSeceret)
      let data = JSON.parse(bodyBytes.toString(CryptoJS.enc.Utf8))
      if (!response.config.url.includes('dashboard/get/report') && (typeof data === 'string' || data instanceof String)) {
        data = JSON.parse(data)
      }
      console.log("response--data--", data)
      return data;
    }
    // If not authorized then reset authorization
    // and redirect to login page

    if (response.status === 401) {
      Vue.prototype.$localStorage.clear();
      return Promise.reject(new Error("USER_ANONYMOUS"));
    } else {
      let err = new Error(response.statusText);
      err.response = response;
      return Promise.reject(err);
    }
  }

  handleError(error) {
    apiCallCount = apiCallCount - 1;
    if (apiCallCount == 0)
      Vue.set(Vue.prototype, '$globalVar', false);
    if (error.response && error.response.status) {
      let bodyBytes = CryptoJS.AES.decrypt(error.response.data, cryptSeceret)
      let data = JSON.parse(bodyBytes.toString(CryptoJS.enc.Utf8))
      if (typeof data === 'string' || data instanceof String) {
        data = JSON.parse(data)
      }
      switch (error.response.status) {
        case 401:
          Vue.prototype.$localStorage.clear();
          window.location.replace('/login');
          return Promise.reject(data);
        case 400:
          return Promise.reject(data);
        case 404:
          return Promise.reject(data);
        case 403:
          return Promise.reject(data);
        default:
          return Promise.reject(data);
      }
    } else if (error.response) {
      let err = new Error(error.response);
      return Promise.reject(err);
    } else {
      let err = new Error(error);
      return Promise.reject(err);
    }
  }
}



export var apiCallCount = 0;
import Vue from 'vue';


const globalVar = Vue.observable({
  loader: false
});
Object.defineProperty(Vue.prototype, '$globalVar', {
  get() {
    return globalVar.loader;
  },
  set(value) {
    globalVar.loader = value;
  }
});
