var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[(
      _vm.$route.name == 'Manage Customers' ||
        _vm.$route.name == 'Verify Flow' ||
        _vm.$route.name == 'Register' ||
        _vm.$route.name == 'Registration Verify' ||
        _vm.$route.name == 'Registration Verified' ||
        _vm.$route.name == 'Pre Register' ||
        _vm.$route.name == 'Login' ||
        _vm.$route.name == 'Verify Email Otp' ||
        _vm.$route.name == 'Verify Phone Otp' ||
        _vm.$route.name == 'Success' ||
        _vm.$route.name == 'Contact Sales' ||
        _vm.$route.name == 'Manage Customer' ||
        _vm.$route.name == 'Signup'
    )?_c('NavBar'):_vm._e(),_c(_vm.layout,{tag:"component"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }