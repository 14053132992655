<template>
  <v-app id="app">
    <NavBar
      v-if="
        $route.name == 'Manage Customers' ||
          $route.name == 'Verify Flow' ||
          $route.name == 'Register' ||
          $route.name == 'Registration Verify' ||
          $route.name == 'Registration Verified' ||
          $route.name == 'Pre Register' ||
          $route.name == 'Login' ||
          $route.name == 'Verify Email Otp' ||
          $route.name == 'Verify Phone Otp' ||
          $route.name == 'Success' ||
          $route.name == 'Contact Sales' ||
          $route.name == 'Manage Customer' ||
          $route.name == 'Signup'
      "
    />
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
const default_layout = "no-sidebar";
export default {
  name: "app",
  components: {
    NavBar,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
  created() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~rfs/scss";
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");
body {
  font-family: "Lato", sans-serif !important;
  background: #f7f7f7 !important;
}
.breadcrumb {
  background: white !important;
  margin-bottom: 0px !important;
  padding: 0.75rem calc(1.25rem + 15px) !important;
}
.breadcrumb > .breadcrumb-item:first-child {
  margin-left: 0.5rem !important;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #505050;
}
.breadcrumb-item a:hover {
  /* text-decoration: none; */
  color: #000000;
}
.navbar {
  // border-bottom: 2px solid #ff8236;
  border-bottom: 3px solid #3D86C6;
  min-height: 75px;
  background: white !important;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(245,105,79)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler-icon {
  border-color: #ff8236 !important;
}
.navbar-dark .navbar-toggler {
  outline: none;
}
input,
select {
  padding: 3px 7px !important;
}
label {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.form-group {
  margin-top: 10px !important;
}
.form-control {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  height: 45px !important;
  color: #495057 !important;
  /* opacity: 0.6; */
}
.head-label {
  font-family: "Lato", sans-serif !important;
  font-size: 24px;
  @include font-size(24px);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #3D86C6;
}
.sub-header-label {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #767676;
}
.label-star {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #EB9B32;
}
.secondry-head-label {
  font-family: "Lato", sans-serif !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}
.button-danger {
  height: 52px !important;
  border-radius: 6px !important;
  background-color: #EB9B32 !important;
  width: 100% !important;
  border-color: #EB9B32 !important;
}
.button-next {
  height: 45px !important;
  border-radius: 6px !important;
  background-color: #EB9B32 !important;
  width: 200px !important;
  border-color: #EB9B32 !important;
  margin-left: auto;
}
.button-pay {
  height: 35px !important;
  border-radius: 6px !important;
  background-color: #EB9B32 !important;
  width: 100px !important;
  border-color: #EB9B32 !important;
  margin-left: auto !important;
}
.login-button,
.contactus-button {
  font-family: "Lato", sans-serif !important;
  min-width: 138px !important;
  min-height: 35px !important;
  border-radius: 4px !important;
  border: solid 1px #EB9B32 !important;
  background-color: #EB9B32 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  text-align: center !important;
  color: #ffffff !important;
}
.contactus-button {
  background-color: #ffffff !important;
  color: #EB9B32 !important;
}
@media (max-width: 768px) {
  .login-button {
    width: calc(50% - 0.5rem);
  }
  .contactus-button {
    width: calc(50% - 0.5rem);
  }
}
@media (min-width: 992px) {
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 540px !important;
  }
}
.code-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .code-container {
    max-width: 264px !important;
  }
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
  border: none !important;
}

.vue-tel-input {
  border: none !important;
  height: 45px;
}
.vue-tel-input > input {
  font-family: "Lato", sans-serif !important;
  border: 1px solid #ced4da !important;
  margin-left: 10px;
  border-radius: 2px;
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  height: 45px !important;
  color: #495057 !important;
}
.vue-tel-input > input::placeholder,
.form-control::placeholder {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  @include font-size(14px !important);
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.21 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #bcbcbc !important;
}
.vti__dropdown {
  border: 1px solid #ced4da !important;
  width: 150px;
  border-radius: 2px;
}
.vti__selection .vti__country-code {
  margin-left: 15px;
}
.vti__dropdown-arrow {
  margin-left: 15px;
}
.vue-tel-input > input:focus,
.vti__dropdown:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.step-header-line-p {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  background-color: #E5E5E5 !important;
  border-radius: 2px;
  min-width: 5rem;
}
.top-p .step-header-p .title-p {
  margin-top: 10px;
  left: 32px;
  min-width: 7rem;
  font-size: 0.8rem !important;
  color: #707070 !important;
  line-height: 1rem !important;
}
.top-p .step-header-p .title-p.current {
  color: #3D86C6 !important;
}
.top-p .step-header-p .title-p.disabled {
  color: #666666 !important;
}
.step-header.active .step-header-content {
  background-color: #3D86C6 !important;
}
.step-header-p.active .step-header-content-p {
  background-color: #3D86C6 !important;
}

// stepper not completed states
.step-header-p .step-header-content-p.one {
  background: url('assets/reg-steps/svg/step1.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p .step-header-content-p.two {
  background: url('assets/reg-steps/svg/step2.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p .step-header-content-p.three {
  background: url('assets/reg-steps/svg/step3.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p .step-header-content-p.four {
  background: url('assets/reg-steps/svg/step4.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

// stepper completed and not disabled states
.step-header-p.active .step-header-content-p.one {
  background: url('assets/reg-steps/svg/step1-completed.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.step-header-p.active .step-header-content-p.two {
  background: url('assets/reg-steps/svg/step2-completed.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.step-header-p.active .step-header-content-p.three {
  background: url('assets/reg-steps/svg/step3-completed.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.step-header-p.active .step-header-content-p.four {
  background: url('assets/reg-steps/svg/step4-completed.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

// stepper current states
.step-header-p.active .step-header-content-p.one.current {
  background: url('assets/reg-steps/svg/step1-active.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p.active .step-header-content-p.two.current {
  background: url('assets/reg-steps/svg/step2-active.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p.active .step-header-content-p.three.current {
  background: url('assets/reg-steps/svg/step3-active.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p.active .step-header-content-p.four.current {
  background: url('assets/reg-steps/svg/step4-active.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

// stepper disabled states
.step-header-p.active .step-header-content-p.one.disabled {
  background: url('assets/reg-steps/svg/step1-disabled.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p.active .step-header-content-p.two.disabled {
  background: url('assets/reg-steps/svg/step2-disabled.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
.step-header-p.active .step-header-content-p.three.disabled {
  background: url('assets/reg-steps/svg/step3-disabled.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
// ----------------------------

.foot {
  display: none !important;
}
.step-header-line, step-header-line-p {
  width: 30px !important;
}
.step-header-content {
  width: 35px !important;
  height: 35px !important;
}
.step-header-content-p {
  width: 50px !important;
  height: 49px !important;
}
.title, .title-p {
  width: 30px !important;
}
@media (min-width: 700px) {
  .top {
    margin-left: auto !important;
    width: 35% !important;
  }
  .top-p {
    width: 65% !important;
  }
}
.heading-regs {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #EB9B32 !important;
  background-color: #EB9B32 !important;
}
.custom-switch .custom-control-label::after {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 3px #EB9B32 !important;
  background-color: #fdfdfe !important;
}
legend {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  color: #363636 !important; 
}
.hidden_header {
  display: none !important;
}
.verified-button {
  height: 40px !important;
  color: #EB9B32 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: smaller !important;
  font-weight: bold !important;
  white-space: nowrap;
  cursor: default !important;
}
.verified-button img {
  height: 13px;
}
@media (min-width: 992px) {
  .card-class {
    max-width: 1200px !important;
  }
}
.modal-header {
  border-bottom: none !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: white !important;
  width: 35px;
  height: 26px;
  opacity: 0.6;
  border-radius: 2px;
  border: solid 1px gray !important;
  background-color: #1976D2 !important;
  font-size: 12px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.page-link {
  z-index: 3;
  color: #1976D2 !important;
  width: 35px;
  height: 26px;
  opacity: 0.6;
  border-radius: 2px;
  border: solid 1px #1976D2 !important;
  background-color: #ffffff !important;
  font-size: 12px;
  line-height: 0.6 !important;
  margin-left: 4px !important;
  font-weight: bold;
}
.thClass {
  text-align: center !important;
}
/* style for pages */
.page-heading {
  font-family: "Lato", sans-serif !important;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1d;
}
.icon-button {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #EB9B32 !important;
  width: 200px !important;
  height: 40px !important;
  color: #EB9B32 !important;
  background-color: #ffffff !important;
  border-color: #EB9B32 !important;
  outline: none !important;
  box-shadow: none !important;
}
.sub-icon-button {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #EB9B32 !important;
  color: #EB9B32 !important;
  background-color: #ffffff !important;
  border-color: #EB9B32 !important;
  outline: none !important;
  box-shadow: none !important;
}
.full-button {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #EB9B32 !important;
  width: 200px !important;
  height: 40px !important;
  color: #ffffff !important;
  background-color: #EB9B32 !important;
  border-color: #EB9B32 !important;
  outline: none !important;
  box-shadow: none !important;
}
.customer-heading {
  font-family: "Lato", sans-serif !important;
  font-size: 20px;
  font-weight: 600;
  color: #EB9B32;
  width: 100%;
}
.sub-customer-heading {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  color: #767676;
  width: 100%;
}
.error-domain {
  height: 46px;
  background-color: #ffeaea;
}
.error-domain-div {
  font-family: "Lato", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #e20e0e;
}
.active-dot {
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background-color: #00cf3b;
  border-radius: 50%;
  display: inline-block;
}
.inactive-dot {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  background-color: #ff2731;
  border-radius: 50%;
  display: inline-block;
}
.unregistered-dot {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  background-color: #767676;
  border-radius: 50%;
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.page-title {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
/* temporarily hiding recaptcha badge */
.grecaptcha-badge {
  display: none;
}
/*  */
// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
//   margin-top: -3px !important;
// }
// .custom-control-input:checked ~ .custom-control-label::before {
//   margin-top: -3px !important;
// }
// .custom-checkbox .custom-control-label::before {
//   margin-top: -3px !important;
// }
// .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before{
//     margin-top: -3px !important;
// }
// .custom-control-label::after {
//   margin-top: -2px !important;
// }

.vti__dropdown-list {
  width: 390px !important;
}
@media (max-width: 509px) {
  .vti__dropdown {
    width: 75px !important;
  }
  .vti__selection .vti__country-code {
    margin-left: 0px !important;
  }
  .vti__dropdown-arrow {
    margin-left: 5px !important;
  }
  .vti__flag {
    margin-left: -5px !important;
  }
  .vue-tel-input > input {
    margin-left: 2px !important;
  }
}
@media (max-width: 375px) {
  .vti__dropdown-list {
    width: 293px !important;
  }
}
@media (max-width: 320px) {
  .vti__dropdown-list {
    width: 238px !important;
  }
}
@media (max-width: 440px) {
  .vti__dropdown-list {
    width: 363px !important;
  }
}
.vue-tel-input > input:focus,
.vti__dropdown:focus {
  box-shadow: none !important;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  margin-right: 0px !important;
}
.multiselect__placeholder {
  padding-left: 14px !important;
  padding-top: 8px !important;
  margin-bottom: 0px !important;
}
.multiselect {
  max-width: 200px;
}
.multiselect__input::placeholder {
  padding-left: 10px !important;
}
.multiselect__single {
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
  padding-top: 6px !important;
}
.multiselect--active:not(.multiselect--above) .multiselect__input {
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
  padding-top: 6px !important;
}
.multiselect__option--highlight {
  background: white !important;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: white !important;
}
.multiselect__option--selected {
  background: white !important;
}
.multiselect__tags {
  min-height: 35px !important;
  padding: 0px !important;
  min-width: 200px !important;
}
.multiselect__tag {
  background: #EB9B32;
  margin-bottom: 0px !important;
  margin-left: 5px;
  margin-top: 5px;
}
.multiselect__input {
  padding: 0px !important;
}
.multiselect__tag-icon:after {
  content: "×";
  color: white;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #EB9B32;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}
@media (max-width: 440px) {
  .multiselect__tags {
    min-height: 30px !important;
    padding: 0px !important;
    min-width: 0px !important;
  }
  .multiselect__select {
    width: 30px !important;
    height: 32px !important;
  }
  .multiselect__placeholder {
    padding-top: 3px !important;
  }
  .multiselect__single {
    font-family: "Lato", sans-serif !important;
    font-size: 12px !important;
  }
  .multiselect--active:not(.multiselect--above) .multiselect__input {
    font-family: "Lato", sans-serif !important;
    font-size: 12px !important;
  }
  .multiselect__tag {
    font-size: 10px !important;
  }
  .multiselect__tag-icon {
    content: "×";
    color: white;
    font-size: 10px;
    margin-top: -2px;
  }
}
.popover.b-popover {
  // margin-top: 20px !important;
  min-width: 150px !important;
}
.list-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1d;
}
.down-arrow {
  transform: rotate(90deg);
  margin-left: 14px;
  height: 10px;
  margin-top: 0px;
}
// .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//   top: 3px !important;
// }
// .custom-control-label::after {
//   margin-top: 0px !important;
// }
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.v-application ul{
  padding-left: 0px !important;
}
.graph-table {
  vertical-align: middle !important;
}
.graph-table .online-offline {
  display: flex;
  align-items: center;
}
.custom-radio .custom-control-label::before{
  top: 0px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after{
  top: -1px;
}
</style>
