const CryptoJS = require("crypto-js");
const cryptSeceret = '#$%+' + process.env.VUE_APP_ACCESS_TOKEN_SECRET + '#$%+'
import Vue from 'vue';
export const isLogedInUser = () => {
    let accessToken = Vue.prototype.$localStorage.getItem('accessToken')
    if(!accessToken){
        Vue.prototype.$localStorage.clear();
        window.location.replace('/login');
        return
    }
    let user = parseJwt(accessToken);
    return Vue.prototype.$localStorage.getItem('orgNum') == user.orgNum;
}

export const accessTokenOrgDetails = () => { 
    let accessToken = Vue.prototype.$localStorage.getItem('accessToken')
    if(!accessToken){
        Vue.prototype.$localStorage.clear();
        window.location.replace('/login');
        return
    }
    let user = parseJwt(accessToken);
    return user;
}

function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload);
}

export const accessTokenDecode = (token) => {
    let bodyBytes  = CryptoJS.AES.decrypt(token, cryptSeceret)
    let decryptedToken = JSON.parse(bodyBytes.toString(CryptoJS.enc.Utf8))
    return decryptedToken;
}

export const getUserRole = () => { 
    let accessToken = Vue.prototype.$localStorage.getItem('accessToken')
    if(!accessToken){
        Vue.prototype.$localStorage.clear();
        window.location.replace('/login');
        return
    }
    let user = parseJwt(accessToken);
    let role = ''
    if(user.customerMapped && user.customerMapped.length > 0){
      role = 'Account_Manager'
    }else{
      role = user.role
    }
    return role;
}