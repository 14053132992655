import Vue from "vue";
import VueRouter from "vue-router";
import { getUserRole } from "../services/CommonService";
Vue.use(VueRouter);

const routeProtect = (to, from, next) => {
  let role = getUserRole();
  let token = Vue.prototype.$localStorage.getItem('accessToken');
  let redirectPath = from.path ? from.path == '/' ? '/login' : from.path : '/login';
  const savedVersion = Vue.prototype.$localStorage.getItem('appVersion');
  if (savedVersion !== Vue.prototype.$currentVersion) {
    redirectPath = '/login'
  } 
  if (token && role) {
    switch (to.path) {
      case '/manage-customers':
        role == 'Super_Admin' || role == 'Account_Manager' ? next() : next(redirectPath);
        break;
      case '/manage-customer':
        role == 'Super_Admin' || role == 'Admin' || role == 'Account_Manager' ? next() : next(redirectPath);
        break;
      case '/add-subscriber':
      case '/edit-subscriber':
        role == 'Super_Admin' || role == 'Admin' || role == 'Account_Manager' ? next() : next(redirectPath);
        break;
      case '/add-user':
      case '/edit-user':
        role == 'Super_Admin' || role == 'Admin' || role == 'Account_Manager' ? next() : next(redirectPath);
        break;
      default:
        next();
    }
  } else {
    next(redirectPath);
  }
}
export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/login' },
    {
      path: "/pre-register",
      name: "Pre Register",
      // component: () => import("../pages/PreRegister.vue")
      redirect: "/register"
    },
    {
      path: "/pre-registration-verify",
      name: "Registration Verify",
      component: () =>
        import("../components/pre-register/PreRegistrationVerify.vue")
    },
    {
      path: "/verify-flow",
      name: "Verify Flow",
      component: () =>
        import("../components/pre-register/VerifyFlow.vue")
    },
    {
      path: "/registration-verified",
      name: "Registration Verified",
      component: () =>
        import("../components/pre-register/PreRegistrationSucess.vue")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("../pages/Register.vue")
    },
    {
      path: "/manage-customers",
      name: "Manage Customers",
      component: () => import("../pages/ManageCustomers.vue"),
      beforeEnter: routeProtect
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      // component: () => import("../pages/Dashboard.vue"),
      component: () => import("../pages/DashboardUpdated.vue"),
      meta: {
        layout: "sidebar"
      },
      beforeEnter: routeProtect
    },
    // {
    //   path: "/customer-profile",
    //   name: "Customer Profile",
    //   component: () => import("../pages/super-admin/CustomerProfile.vue"),
    //   // meta: {
    //   //   layout: "sidebar"
    //   // },
    //   beforeEnter: routeProtect
    // },
    {
      path: "/manage-customer",
      name: "Manage Customer",
      component: () => import("../pages/super-admin/ManageCustomer.vue"),
      // meta: {
      //   layout: "sidebar"
      // },
      beforeEnter: routeProtect
    },
    {
      path: "/manage-subscribers",
      name: "Manage Subscribers",
      component: () => import("../pages/ManageSubscribers.vue"),
      meta: {
        layout: "sidebar"
      },
      beforeEnter: routeProtect
    },
    {
      path: "/devices",
      name: "Devices",
      component: () => import("../pages/devices/ListDevicesICCID.vue"),
      meta: {
        layout: "sidebar"
      },
      beforeEnter: routeProtect
    },
    {
      path: "/manage-devices",
      name: "Manage Devices",
      component: () => import("../pages/devices/ManageDevices.vue"),
      meta: {
        layout: "sidebar"
      },
      beforeEnter: routeProtect
    },
    {
      path: "/add-device",
      name: "Add Device",
      component: () => import("../components/manage-devices/AddDevice.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Devices",
          label: "Manage Devices"
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/edit-device",
      name: "Edit Devices",
      component: () => import("../components/manage-devices/EditDevice.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Devices",
          label: "Manage Devices"
        }
      }
    },
    {
      path: "/add-subscriber",
      name: "Add Subscribers",
      component: () => import("../components/manage-subscribers/AddSubscriber.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Subscribers",
          label: "Manage Subscribers"
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/edit-subscriber",
      name: "Edit Subscribers",
      component: () => import("../components/manage-subscribers/edit/EditSubscriber.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Subscribers",
          label: "Manage Subscribers"
        }
      }
    },
    {
      path: "/manage-users",
      name: "Manage Users",
      component: () => import("../pages/ManageUsers.vue"),
      meta: {
        layout: "sidebar"
      },
      beforeEnter: routeProtect
    },
    {
      path: "/add-user",
      name: "Add User",
      component: () => import("../components/manage-users/AddUser.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Users",
          label: "Manage Users"
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/edit-user",
      name: "Edit User",
      component: () => import("../components/manage-users/EditUser.vue"),
      meta: {
        layout: "sidebar",
        breadcrumb: {
          parent: "Manage Users",
          label: "Manage Users"
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../pages/Login.vue")
      // component: () => import("../pages/DashboardUpdated.vue"),
    },
    {
      path: "/verify-email-otp",
      name: "Verify Email Otp",
      component: () => import("../components/login/VerifyLoginEmail.vue")
    },
    {
      path: "/verify-phone-otp",
      name: "Verify Phone Otp",
      component: () => import("../components/login/VerifyLoginOtp.vue")
    },
    // {
    //   path: "/settings/:activeTab?",
    //   name: "Settings",
    //   component: () => import("../pages/Settings.vue"),
    //   meta: {
    //     layout: "sidebar"
    //   },
    //   beforeEnter: routeProtect
    // },
    {
      path: "/contact-sales",
      name: "Contact Sales",
      component: () => import("../pages/ContactSales.vue")
    },

    // TEST ROUTES START, TODO: to be removed
    {
      path: "/test/success",
      name: "Success",
      component: () => import("../components/registration/SuccessPage.vue")
    },
    // TEST ROUTES END
    {
      path: "/signup",
      name: "Signup",
      component: () => import("../pages/signup/Signup.vue")
    },
  ]
});