import {
    store
} from '../store/store';
import {
    ApiHelper
} from "./Helper/ApiHelper";
import Vue from 'vue';
const apiHelper = new ApiHelper();
export const registerOrg = async () => {
    var {
        state
    } = store;
    let domains = [];
    let uri = '/organization/ent';
    state.portalUser.role = 'Admin';
    state.regUser = state.portalUser;
    domains = state.domains.map(domain => {
        return domain.replace("http://", "").replace("https://", "").replace("www.", "").split(/[/?#]/)[0];
    });
    state.domains = domains;
    console.log(state.domains);
    return apiHelper.post(uri, state);
}

export const getEstimate = async () => {
    const {
        state
    } = store;
    const plan = {
        plan_id: state.subscription.plan,
        plan_quantity: state.subscription.planQuantity,
        billing_address: state.billingAddress
    }
    const uri = "/estimate";
    return apiHelper.post(uri, plan);
}

export const createPI = (amount) => {
    const uri = "/create_pi";
    return apiHelper.post(uri, {
        amount
    });
}

export const generateOTP = (data) => {
    const uri = "/generateOtp";
    return apiHelper.post(uri, {
        data
    });
}

export const validateOTP = (email, emailOtp, mobOtp) => {
    const validateObj = { email };
    if (emailOtp) validateObj.emailOtp = emailOtp;
    if (mobOtp) validateObj.mobOtp = mobOtp;
    const uri = "/validateOtp";
    return apiHelper.post(uri, validateObj);
}

export const getOrgInfo = () => {
    const orgNum = Vue.prototype.$localStorage.getItem('orgNum') || '1';
    const uri = `/organization/ent/${orgNum}`;
    return apiHelper.get(uri);
}

export const updateOrg = async (data) => {
    const orgNum = Vue.prototype.$localStorage.getItem('orgNum') || '1';
    let uri = `/organization/ent/update`;
    return apiHelper.post(uri, { ...data, orgNum });
}