import {
    ApiHelper
} from "./Helper/ApiHelper";

const apiHelper = new ApiHelper();

export const generateOTP = (data) => {
    const uri = "/login/otp";
    return apiHelper.post(uri,
        data);
}

export const verifyOtp = (data) => {
    const uri = "/login/verify";
    return apiHelper.post(uri,
        data);
}

// Company Form
export const companyForm = (data) => {
    const uri = "/companyForm";
    return apiHelper.post(uri,
        data);
}

export const logoutUser = () => {
    const uri = "/logout";
    return apiHelper.post(uri,{});
}

export const userSessionStatus = (data) => {
    const uri = "/login/session/status";
    return apiHelper.post(uri,data);
}