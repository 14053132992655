import {
    extend
} from 'vee-validate';
import {
    required,
    confirmed
} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});
extend('confirmed', {
    ...confirmed,
    message: '{_field_}s are not matching'
});
extend('max', (value, max) => {
    if (value.length <= parseInt(max[0])) {
        return true;
    }
    return `{_field_} length should not exceed ${parseInt(max[0])}`;
});
extend('min', (value, min) => {
    if (value.length >= parseInt(min[0])) {
        return true;
    }
    return `{_field_} length should not be less than ${parseInt(min[0])}`;
});
extend('integer', (value) => {
    if ((/^(?:\s|^)\d+(?=\s|)$/.test(value))) {
        return true;
    }
    return '{_field_} must be a number'
});
extend('positive', value => {
    if (value >= 0) {
        return true;
    }
    return '{_field_} must be a positive number';
});
extend('email', (value) => {
    let match = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/.test(value))
    if (!match)
        return false;
    let domain = value.split("@");
    var forbiddenDomains = ["gmail.com"];
    if (forbiddenDomains.indexOf(domain[1].toLowerCase()) >= 0)
        return 'Invalid {_field_}';
    return true;
});
extend('domain_name', (value) => {
    // if ((/^(?!(live|hotmail|outlook|aol|yahoo|rocketmail|gmail|gmx\.com|mail\.com|inbox\.com|icloud|aim|yandex|zoho))[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,6}$/.test(value))) {
    //     return true;
    // }
    if ((/^(https?:\/\/)?(www.)?(?!(live|hotmail|outlook|aol|yahoo|rocketmail|google|gmail|gmx\.com|mail\.com|inbox\.com|icloud|aim|yandex|zoho))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]?\.[a-z]{2,6}(\/)?$/.test(value)))
    {
        return true;
    }
    return 'Invalid domain name';
});
extend('alpha_num_spaces', (value) => {
    if ((/^[a-zA-Z0-9 ]*$/.test(value))) {
        return true;
    }
    return '{_field_} can contain only numbers, alphabet and spaces'
});
extend('zip_code', (value) => {
    if ((/^[a-z\d\-_\s]+$/.test(value))) {
        return true;
    }
    return 'Invalid {_field_}'
});