<template>
  <b-modal
    :id="modalId"
    :visible="value"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    centered
    body-class="p-0"
    content-class="card rounded-circle"
    size="md"
  >
    <div class="card pb-3 alert-content">
      <div class="mx-4">
        <div class="row d-block pt-3 my-2" v-if="isGLobalCalender"></div>
        <div class="row d-block m-0 my-2" v-else>
          <img v-if="isConfirmation||isSignOut" src="../assets/warning.svg" class="success-icon" />
          <img v-else src="../assets/verified.svg" class="success-icon" />
        </div>
        <h5
          class="success-text mb-3"
          :style="isConfirmation||isSignOut?'color: black;':''"
        >{{isSignOut?"Are you sure that you want to sign out?":alertMsg}}</h5>
        <hr class="section-seperator" />

        <div class="row mx-0 my-2" style="height:35px">
          <div v-if="!isSuccessfully" class="col p-0 px-1">
            <button class="addButton" @click="onAdd">
              <template v-if="isGLobalCalender"></template>
              <template v-else>
                   <template v-if="!isConfirmation&&!isSignOut">+</template>
              </template>
           
              <i v-if="triggering" class="fas fa-circle-notch fa-spin"></i>
              {{isSignOut?"Yes, Sign Out":addMoreText}}
            </button>
          </div>
          <div v-if="!isSuccessfully" class="col p-0 px-1">
            <button class="nextButton" @click="onNext">{{isSignOut?"CANCEL":nextText}}</button>
          </div>
          <div v-if="isSuccessfully" class="col-12 p-0 px-1">
            <button class="addButton" @click="onAdd">
              <template v-if="!isConfirmation&&!isSignOut"></template>
              {{isSignOut?"Yes, Sign Out":addMoreText}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { logoutUser } from "../services/LoginService";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    modalId: {
      type: String,
      default: "ijura-alert-modal"
    },
    alertMsg: {
      type: String,
      default: "Added Successfully"
    },
    addMoreText: {
      type: String,
      default: "Add more"
    },
    nextText: {
      type: String,
      default: "DONE"
    },
    isConfirmation: {
      type: Boolean,
      default: false
    },
    isSignOut: {
      type: Boolean,
      default: false
    },
    isSuccessfully: {
      type: Boolean,
      default: false
    },
    isContactSales: {
      type: Boolean,
      default: false
    },
    changePlane:{
      type:Boolean,
      default:false
    },
    isGLobalCalender:{
      type:Boolean,
      default:false
    },
  },

  data() {
    return {
      triggering:false
    };
  },
  mounted() {},
  methods: {
    onAdd() {
      if (this.isSignOut) {
        this.$emit("signOut");
        this.$emit("input", false);
        this.signOut();
      }else if(this.isContactSales){
        this.triggering = true
        this.$emit("onSubmit");
        this.$emit("input", false);
        this.triggering=false
      } else if(this.changePlane){
        this.$emit("changeSelectedPlan");
        this.$emit("input", false);
      } else {
        this.$emit("onAdd");
        this.$emit("input", false);
      }
    },
    onNext() {
   if(this.changePlane){
        this.$emit("cancelSelectedPlan");
        this.$emit("input", false);
      }else{
 this.$emit("onNext");
      this.$emit("input", false);
      }
     
    },
    signOut() {
      logoutUser({
      })
        .then((res) => {
          console.log(res)
          sessionStorage.clear();
          this.$localStorage.clear();
          this.$router.push("/login");
        })
        .catch((e) => {
          console.log(e);
          sessionStorage.clear();
          this.$localStorage.clear();
          this.$router.push("/login");
        });
    }
  }
};
</script>
<style scoped>
.success-icon {
  height: 57px;
  width: 57px;
}
.success-text {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  color: #184ed5;
}
.section-separator {
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e0e3eb;
  background-color: #ffffff;
}
.addButton {
  font-size: 13px;
  border-radius: 4px;
  border: solid 1px #EB9B32;
  color: #ffffff;
  background-color: #EB9B32;
  font-weight: bold;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.nextButton {
  font-size: 13x;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #EB9B32;
  color: #EB9B32 !important;
  background-color: #ffffff;
  font-weight: bold;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>