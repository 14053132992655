<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="white" style="z-index:100" :class="$route.name == 'Signup' ?'navbar-height':''">
      <b-navbar-brand class="ml-md-5">
        <!-- @click="goToHome" -->
        <img src="../assets/ijura_logo_updated.svg" alt="" style="cursor:pointer;width: 59px;height: 54px;object-fit: contain;"/>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto d-inline" v-if="$route.name != 'Signup'">
          <div v-if="$route && ($route.name != 'Manage Customers' && $route.name != 'Manage Customer')">
            <router-link
              v-if="$route && $route.path != '/login'"
              :to="'/login'"
            >
              <b-button class="mr-2 mb-0 login-button">LOGIN</b-button>
            </router-link>
            <router-link v-else :to="'/pre-register'">
              <b-button class="mr-2 mb-0 login-button">REGISTER</b-button>
            </router-link>
            <a style="display:inline-block;position:relative;padding-top:5px" href="https://ijura.com/#contact"> 
            <b-button class="contactus-button">
              <img src="../assets/phone.svg" class="mr-2" />CONTACT US
            </b-button>
            </a>
          </div>
          <div v-else style="height: 64px;">
            <img
            class="mr-2"
            style="height: 100%;padding: 8px;"
            @click="signOut"
            src="../assets/tata-logo.png"
            alt="logo"
          />
             <img
            class="p-3 mr-2"
            style="cursor:pointer"
            @click="signOut"
            src="../assets/logout.svg"
            alt="logout"
          />
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <PopupAlert v-model="signOutAlert" :isSignOut="true" />
  </div>
</template>
<script>
import PopupAlert from "../widgets/PopupAlert";
export default {
  components: {
    PopupAlert,
  },
  data() {
    return {
      signOutAlert: false,
    };
  },
  methods: {
    signOut() {
      this.signOutAlert = true;
    },
    goToHome(){
      window.location.href = 'https://www.ijura.com';
    }
  },
};
</script>
<style scoped>
.navbar-height{
  height: 71px;
}
</style>
