<template>
  <div>
    <NavBar
      class="position-fixed"
      style="top: 0; left: 0"
      v-if="getCurrentRoute && getCurrentRoute != 'Login'"
      @getSelectedMenuEvent="onToggleCollapse()"
      :mainHeaderClass="mainHeaderClass"
      :selectedPage="selectedPage"
      :isMobile="isMobile"
      :collapsed="collapsed"
    />
    <!-- <div class="position-relative" style="top: 65px">
      <div
        style="width: 100%;
          height: 100%;!important
          position: fixed;
          top: 0;
          bottom: 0;"
      >
        <sidebar-menu
          :menu="isMobile ? mobileMenu : menu"
          @collapse="onCollapse($event)"
          :collapsed="collapsed"
          :width="isMobile ? '75%' : '205px'"
          :disableHover="true"
          @item-click="getClickedItem($event)"
          :widthCollapsed="collapsed && isMobile ? '0px' : '100px'"
          :hideToggle="true"
          :rtl="isMobile"
          :showChild="true"
        >
          <div slot="header" class="heading_class">
            <div
              class="close-sidebar ml-2 mt-3"
              @click="onToggleCollapse"
              v-if="isMobile"
            ></div>
            <span
              v-if="userRole == 'Super_Admin' && isMobile"
              style="
                margin-top: auto;
                display: block;
                margin-top: 10px;
                margin-left: 10px;
              "
            >
              <span
                >{{ orgName
                }}<img
                  class="down-arrow"
                  id="popover-target-2"
                  src="../assets/right-arrow.png"
                  style="cursor: pointer"
              /></span>
              <b-popover
                target="popover-target-2"
                triggers="hover"
                placement="top"
              >
                <div
                  class="row p-2 list-class"
                  @click="goToManageCustomerPage"
                  style="cursor: pointer; margin-bottom: 5px"
                >
                  <span class="mr-2">Manage {{ orgName }}</span
                  ><img
                    class="ml-auto mt-1"
                    style="height: 10px"
                    src="../assets/right-arrow.png"
                  />
                </div>
                <div
                  class="row"
                  style="
                    border-top: 1px solid #dfdfdf;
                    margin-left: -12px;
                    margin-right: -12px;
                  "
                ></div>
                <div
                  class="row p-2 list-class"
                  @click="changeOrganization"
                  style="cursor: pointer; margin-top: 5px"
                >
                  <span class="mr-2">Change Customer</span
                  ><img
                    style="height: 10px"
                    class="ml-auto mt-1"
                    src="../assets/right-arrow.png"
                  />
                </div>
              </b-popover>
              <br />
              <span class="status">
                <span
                  class="active-dot"
                  v-if="orgStatus == true || orgStatus == 'true'"
                ></span>
                <span class="inactive-dot" v-else></span>
                <span v-if="orgStatus == true || orgStatus == 'true'"
                  >Active</span
                >
                <span v-else>Inactive</span>
              </span>
            </span>
          </div>
          <span slot="dropdown-icon">
            <i class="fas fa-angle-right"></i>
          </span>
        </sidebar-menu>
      </div>
      <b-breadcrumb
        v-if="showBreadcrumb"
        :items="breadcrumbItems"
        class="breadcrumb"
        :class="{ openedSideBar: !collapsed, closedSideBar: collapsed }"
      />
      <router-view
        :class="{
          openedSideBar: !isMobile && !collapsed,
          closedSideBar: !isMobile && collapsed,
          mobileSideBar: isMobile,
        }"
      />
    </div> -->
    <router-view
      />
    <PopupAlert v-model="signOutAlert" :isSignOut="true" />
    <PopupAlert
      v-model="changeOrg.showAlert"
      :changeOrg="true"
      :organizationId="selected"
      :alertMsg="changeOrg.alertMsg"
      :addMoreText="changeOrg.addMoreText"
      :nextText="changeOrg.nextText"
      @onAdd="changeOrganizations"
      :isConfirmation="true"
    >
    </PopupAlert>
  </div>
</template>
<script>
import { SidebarMenu } from "vue-sidebar-menu";
import NavBar from "../components/NavbarDashboard";
const ESCAPE_LOGOUT_HASH = "#logout";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import { isMobile } from "is-mobile";
import PopupAlert from "../widgets/PopupAlert";
import { getOrgInfo } from "../services/RegistrationService";
import { getUserRole } from "../services/CommonService"; 
export default {
  components: {
    // SidebarMenu,
    NavBar,
    PopupAlert,
  },
  data() {
    return {
      orgData: [],
      selectedPage: "Dashboard",
      menu: [
        {
          href: "/dashboard",
          name: "Dashboard",
          title: "Dashboard",
          icon: {
            element: "div",
            class: "dashboard-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-subscribers",
          name: "Manage Subscribers",
          title: "Manage Subscribers",
          icon: {
            element: "div",
            class: "subs-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-users",
          name: "Manage Users",
          title: "Manage Users",
          icon: {
            element: "div",
            class: "users-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-devices",
          name: "Manage Devices",
          title: "Manage Devices",
          icon: {
            element: "div",
            class: "users-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
      ],
      mobileMenu: [
        {
          href: "/dashboard",
          name: "Dashboard",
          title: "Dashboard",
          icon: {
            element: "div",
            class: "dashboard-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-subscribers",
          name: "Manage Subscribers",
          title: "Manage Subscribers",
          icon: {
            element: "div",
            class: "subs-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-users",
          name: "Manage Users",
          title: "Manage Users",
          icon: {
            element: "div",
            class: "users-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        {
          href: "/manage-devices",
          name: "Manage Devices",
          title: "Manage Devices",
          icon: {
            element: "div",
            class: "users-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
        // {
        //   href: "/settings/manageOrgInfo",
        //   name: "Manage Organization Info",
        //   title: "Settings",
        //   icon: {
        //     element: "div",
        //     class: "settings-icon",
        //   },
        //   disabled: false,
        //   attributes: {
        //     hidden: false,
        //   },
        //   child: [
        //     {
        //       href: "/settings/manageOrgInfo",
        //       title: "Manage Organization Info",
        //     },
        //     {
        //       href: "/settings/manageBillingInfo",
        //       title: "Manage Billing Info",
        //       disabled: true,
        //     },
        //     {
        //       href: "/settings/manageSubscription",
        //       title: "Manage Subscription",
        //     },
        //     {
        //       href: "/settings/manageSubscriptionCode",
        //       title: "Manage Subscription Code",
        //     },
        //     {
        //       href: "/settings/manageCustomers",
        //       title: "Manage Customers",
        //     },
        //   ],
        // },
        {
          // href: "/login",
          name: "Signout",
          title: "Sign Out",
          icon: {
            element: "div",
            class: "logout-icon",
          },
          disabled: false,
          attributes: {
            hidden: false,
          },
          hidden: false,
        },
      ],
      breadcrumbItems: [
        { text: "", to: "" },
        { text: "", active: true },
      ],
      collapsed: screen.width > 768 ? false : true,
      rtl: screen.width > 768 ? false : true,
      showSidebarButton: 1,
      moduleId: 1,
      showBreadcrumb: false,
      isMobile: false,
      hideSidebarOnMobile: true,
      userRole: "",
      orgName: "",
      signOutAlert: false,
      changeOrg: {
        showAlert: false,
        alertMsg: "Are you want to  change the customer ?",
        data: "",
        addMoreText: "CHANGE",
        nextText: "CANCEL",
      },
      orgStatus: Boolean,
      profileType: "",
    };
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    },
    mainHeaderClass() {
      return (!this.collapsed && screen.width >= 768) ||
        (!this.collapsed && screen.width <= 768)
        ? "main-header-open"
        : "main-header-collapse";
    },
  },
  created() {
    window.addEventListener("resize", this.isMobileCheck);
    this.isMobileCheck();
    this.determineBreadcrumb();
    this.userRole = getUserRole()
    this.orgName = this.$localStorage.orgName ? this.$localStorage.getItem("orgName") : "";
    this.orgStatus = this.$localStorage.orgStatus
      ? this.$localStorage.getItem("orgStatus")
      : "";
    if (this.userRole == "Super_Admin" || this.userRole == "Account_Manager") {
      this.mobileMenu.splice(this.mobileMenu.length - 1, 0, {
        href: "/manage-customer",
        name: "Manage Organization Info",
        title: "Settings",
        icon: {
          element: "div",
          class: "settings-icon",
        },
        disabled: false,
        attributes: {
          hidden: false,
        },
      });
    }
    /*
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
    });
    */
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileCheck);
  },
  mounted() {
    this.getOrgInfo();
    this.getWindowWidth(null);
    /*
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
    });
    */
  },
  methods: {
    getOrgInfo() {
      getOrgInfo()
        .then((res) => {
          if (res.success) {
            if (
              res.response &&
              res.response.otherData &&
              res.response.otherData.profileType
            ) {
              if (res.response.otherData.profileType === "iot") {
                this.menu[1].hidden = true;
                this.menu[2].hidden = true;
                this.menu[0].hidden = false;
                this.menu[3].hidden = false;
              } else {
                this.menu[1].hidden = false;
                this.menu[2].hidden = false;
                this.menu[0].hidden = false;
                this.menu[3].hidden = true;
              }
              this.$localStorage.setItem(
                "profileType",
                res.response.otherData.profileType
              );
            } else {
              this.$localStorage.setItem("profileType", "byod");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeOrganization() {
      console.log(this.selected);
      this.changeOrg.showAlert = true;
    },
    goToManageCustomerPage() {
      this.$router.push("/manage-customer");
    },
    changeOrganizations() {
      this.$router.push("/manage-customers");
    },
    isMobileCheck() {
      this.getWindowWidth();
      if (this.hideSidebarOnMobile)
        this.isMobile =
          document.documentElement.clientWidth < 768 || isMobile();
    },
    getClickedItem(event) {
      this.selectedPage = event.target.innerText;
      if (this.isMobile) this.collapsed = true;

      if (this.selectedPage == "Sign Out") this.signOut();
    },
    onToggleCollapse() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        this.mainHeaderClass = "main-header-collapse";
      } else {
        this.mainHeaderClass = "main-header-open";
      }
    },
    getWindowWidth() {
      this.collapsed =
        document.documentElement.clientWidth > 768 ? false : true;
      if (this.collapsed) {
        let collapseBtn = document.getElementsByClassName("collapse-btn")[0];
        collapseBtn && collapseBtn.classList.remove("collapse-btn");
        collapseBtn && collapseBtn.classList.add("after-minimize");
        this.showSidebarButton = 0;
      } else {
        let collapseBtn = document.getElementsByClassName("after-minimize")[0];
        collapseBtn && collapseBtn.classList.remove("after-minimize");
        collapseBtn && collapseBtn.classList.add("collapse-btn");
        this.showSidebarButton = 1;
      }
    },
    onCollapse() {
      if (this.showSidebarButton == 1) {
        this.collapsed = !this.collapsed;
        let collapseBtn = document.getElementsByClassName("collapse-btn")[0];
        collapseBtn.classList.remove("collapse-btn");
        collapseBtn.classList.add("after-minimize");
        this.showSidebarButton = 0;
      } else {
        this.collapsed = !this.collapsed;
        let collapseBtn = document.getElementsByClassName("after-minimize")[0];
        collapseBtn.classList.remove("after-minimize");
        collapseBtn.classList.add("collapse-btn");
        this.showSidebarButton = 1;
      }
    },
    getRouteMap() {
      if (sessionStorage.getItem("routeMap")) {
        // Code written for page refresh
        let json = JSON.parse(sessionStorage.getItem("routeMap"));
        json = json.filter((e) => e !== "[Unknown]");
        let newMap = new Map(json);
        return newMap;
      } else {
        return new Map();
      }
    },
    addRouteMap(_currentRoute) {
      let _routeMap = new Map();
      if (sessionStorage.getItem("routeMap")) {
        // Code written for page refresh
        let json = JSON.parse(sessionStorage.getItem("routeMap"));
        json = json.filter((e) => e !== "[Unknown]");
        _routeMap = new Map(json);
      }
      if (_currentRoute.hash !== ESCAPE_LOGOUT_HASH) {
        _routeMap.set(_currentRoute.name, _currentRoute);
      }
      let _routeMapArray = Array.from(_routeMap.entries());
      let _routeString = JSON.stringify(
        _routeMapArray,
        this.censor(_routeMapArray)
      );
      sessionStorage.setItem("routeMap", _routeString);
    },
    // Code used for breadcumbs issue
    censor(censor) {
      var i = 0;
      return function (key, value) {
        if (
          i !== 0 &&
          typeof censor === "object" &&
          typeof value == "object" &&
          censor == value
        )
          return "[Circular]";
        if (i >= 29)
          // seems to be a harded maximum of 30 serialized objects?
          return "[Unknown]";
        ++i; // so we know we aren't using the original object anymore
        return value;
      };
    },
    clearRouteMap() {
      sessionStorage.removeItem("routeMap");
    },
    determineBreadcrumb() {
      let currentRoute = this.$route;
      //if parent is present, it means it is sub-route
      if (
        currentRoute.meta &&
        currentRoute.meta.breadcrumb &&
        currentRoute.meta.breadcrumb.parent
      ) {
        //look for parent route in map
        let parentRouteName = currentRoute.meta.breadcrumb.parent;
        if (this.getRouteMap() && this.getRouteMap().has(parentRouteName)) {
          let label = currentRoute.meta.breadcrumb.label;
          this.showBreadcrumb = true;
          let parentRoute = this.getRouteMap().get(parentRouteName);
          this.breadcrumbItems[0].text = label ? label : parentRoute.name;
          this.breadcrumbItems[0].to = parentRoute.path;
          this.breadcrumbItems[1].text = "";
        }
      } else {
        //no parent route, reset routemap
        this.clearRouteMap();
        //hide breadcrumb
        this.showBreadcrumb = false;
      }
      this.addRouteMap(currentRoute);
    },
    signOut() {
      this.signOutAlert = true;
    },
  },
  watch: {
    $route() {
      this.determineBreadcrumb();
    },
  },
};
</script>
<style lang="scss">
.v-sidebar-menu.vsm_collapsed {
  .vsm--link {
    padding-left: 36px;
  }
}
.v-sidebar-menu.vsm_expanded {
  .close-sidebar {
    opacity: 1;
    transform: rotate(-90deg);
  }
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active .vsm--icon,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active .vsm--icon {
  color: #555a5f;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  color: #555a5f;
}
.v-sidebar-menu.vsm_collapsed
  .vsm--link_level-1.vsm--link_level-1.vsm--link_exact-active:hover,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_active:hover {
  background-color: #ddeaf5 !important;
}
.v-sidebar-menu .vsm--link_hover {
  background-color: transparent;
}
.v-sidebar-menu .vsm--link:hover {
  color: #555a5f;
  background-color: #ddeaf5 !important;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: #ddeaf5 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #b4b4b4;
  position: relative;
  top: 1px;
}
.openedSideBar {
  margin-left: 205px;
  transition: all 0.3s ease;
}
.closedSideBar {
  margin-left: 100px;
  transition: all 0.3s ease;
}
.mobileSideBar {
  margin-left: 0px;
}
.vsm-collapsed .vsm-dropdown {
  overflow: visible !important;
}
.v-sidebar-menu .vsm-dropdown > .vsm-list .vsm-link > svg {
  margin: 5px 5px 0 5px !important;
}
.v-sidebar-menu .vsm-item.mobile-item > .vsm-link {
  display: none;
}
.v-sidebar-menu {
  padding-top: 80px;
  background-color: white !important;
  height: 100vh !important;
  overflow: visible;
  z-index: 100 !important;
  /* transition: 0s width; */
  font-weight: 500 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);
}
.collapse-btn {
  background-color: #142f5b !important;
  position: sticky !important;
}
.vsm-collapsed > .vsm-list {
  width: 80px !important;
  padding-right: 17px;
}
.v-sidebar-menu > .vsm-list {
  height: 69% !important;
  width: 85%;
}
.v-sidebar-menu .vsm-item {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #8c95ad;
}
.v-sidebar-menu .vsm-item.first-item.active-item > .vsm-link {
  box-shadow: none !important;
  opacity: 1 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.v-sidebar-menu .vsm-item.first-item > .vsm-link:hover {
  color: #fff;
  background: none !important;
}
.v-sidebar-menu .vsm-item.first-item > .vsm-link > .vsm-icon {
  width: 16px !important;
  height: 16px !important;
  margin-top: 5px;
  /* background-color: transparent !important; */
  transition: 0.006s;
}
.v-sidebar-menu .vsm-item.first-item > .vsm-link > .vsm-icon:hover {
  width: 20px !important;
  height: 20px !important;
  margin-top: 4px;
}
.v-sidebar-menu .vsm-default .vsm-item.first-item.open-item > .vsm-link {
  background-color: #142f5b !important;
}
.v-sidebar-menu .vsm-dropdown > .vsm-list {
  background: #eb9b32 !important;
  opacity: 0.2;
}

.vsm-dropdown > .vsm-list .vsm-link {
  color: #8c95ad !important;
}
.vsm-dropdown > .vsm-list .vsm-link > svg {
  color: #8c95ad !important;
}

.vsm-dropdown > .vsm-list > .active-item > .vsm-link {
  color: #fff !important;
}
.vsm-dropdown > .vsm-list > .active-item > .vsm-link > .vsm--link_level-2 {
  color: #8c95ad !important;
}

.vsm-dropdown > .vsm-list > .active-item > .vsm-link > svg {
  color: #1285ff !important;
}

.v-sidebar-menu.vsm-default .vsm-item.first-item.open-item > .vsm-link {
  background: transparent !important;
}
.v-sidebar-menu .vsm-dropdown > .vsm-list .vsm-link {
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 20px;
}
.vsm-collapsed .vsm-dropdown > .vsm-list .vsm-link {
  margin: 0 !important;
}
.v-sidebar-menu .vsm-item.first-item.active-item > .vsm-link > .vsm-icon {
  color: #1285ff !important;
}

.v-sidebar-menu .vsm-item.active-item > .vsm-link,
.v-sidebar-menu .vsm-item.parent-active-item > .vsm-link {
  font-weight: 500;
  box-shadow: none !important;
}

.v-sidebar-menu .vsm-item.first-item {
  font-weight: 500 !important;
}

.v-sidebar-menu .vsm-item.first-item.active-item {
  font-weight: 500 !important;
}
.v-sidebar-menu .vsm-item.first-item > .vsm-link,
.v-sidebar-menu .vsm-item.first-item > .vsm-link > .vsm-icon {
  color: #8c95ad !important;
}
.v-sidebar-menu .collapse-btn::before {
  /* content: url("../assets/left-arrow.svg") !important; */
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-left: -194px !important;
  font-size: 14px;
}
.v-sidebar-menu .collapse-btn::after {
  position: absolute;
  content: "Minimize" !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-left: 12px;
  color: #8c95ad;
  top: 16px !important;
}
.after-minimize {
  border: none;
  background: transparent;
}
.after-minimize:focus {
  outline: none;
}
.after-minimize::after {
  position: absolute;
  /* content: url("../assets/min_ic.svg") !important; */
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  /* margin-left: 10px; */
  margin-top: 0px !important;
}
.v-sidebar-menu .collapse-btn {
  outline: none;
  position: relative !important;
  margin-left: 0;
  padding-left: 30px;
  left: 0;
  width: 85%;
}
.v-sidebar-menu .vsm-icon {
  color: white;
  margin-top: 5px;
}
.v-sidebar-menu .vsm-arrow {
  /* content: url("../assets/sidebar_dropdown_arrow.svg"); */
  transform: translateY(-50%) rotate(-90deg) !important;
  width: 15px !important;
  margin-right: 5px;
  right: 0 !important;
}
.v-sidebar-menu .vsm-arrow.open-arrow {
  /* content: url("../assets/sidebar_dropdown_arrow.svg"); */
  transform: translateY(-50%) rotate(0deg) !important;
  width: 15px;
  margin-right: 5px;
}
.v-sidebar-menu .vsm-header {
  border-top: 1px solid #8c95ad;
  width: 180px;
  position: absolute;
  /* bottom: 115px; */
  text-align: left;
  top: 78vh !important;
  white-space: unset !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 0.85rem !important;
  color: #ffffff;
  font-family: Roboto !important;
  line-height: 1.2rem;
  text-transform: none !important;
  margin-left: 0px;
}
.v-sidebar-menu .vsm-header::before {
  position: absolute;
  top: 1rem;
  left: -0.1rem;
  opacity: 0.4;
  right: 1.5rem;
  white-space: pre-wrap;
  content: " © 2019 LIFEdata.AI S.A. \A All Rights Reserved \A v.0.0.2" !important;
  font-size: 12px;
}
.v-sidebar-menu .vsm-link {
  font-size: 18px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.vsm-mobile-bg,
.mobile-item {
  display: none !important;
}

.v-sidebar-menu .vsm-link[disabled] {
  opacity: 0.4 !important;
  pointer-events: none;
}
.vsm-collapsed {
  width: 80px !important;
}

.vsm-link .router-link-exact-active .router-link-active {
  opacity: 1 !important;
}

.v-sidebar-menu .vsm-dropdown > .vsm-list .vsm-link:hover {
  background-color: white !important;
}
@media (max-width: 768px) {
  .breadcrumb {
    padding-left: 22px !important;
  }
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  /* box-shadow: 3px 0px 0px 0px #f47d35 inset; */
  box-shadow: none;
  border-right: 2px solid #3d86c6;
  background-color: #ddeaf5 !important;
  outline: none;
  color: #3d86c6;
  font-weight: normal;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background: transparent !important;
  height: 25px;
  width: 25px;
  color: #555a5f;
}
.v-sidebar-menu .vsm--mobile-bg {
  background: transparent !important;
}
.v-sidebar-menu .vsm--list {
  transition: all 0.3s ease;
  width: 100%;
}
.v-sidebar-menu .vsm--link {
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #555a5f;
  font-size: 14px;
  min-height: 51.25px;
}
.v-sidebar-menu .vsm--mobile-item {
  display: none;
}
.v-sidebar-menu .vsm--link:focus {
  outline: none;
}
.v-sidebar-menu .vsm--header {
  padding-left: 25px;
  padding-top: 10px;
  /* padding: 20px; */
  padding-bottom: 10px;
  /* border-bottom: 1px solid #ffffff; */
}
.main-header-open {
  height: 90px;
}
.main-header-collapse {
  height: 90px;
}
.heading_class {
  text-align: left;
  max-height: 180px;
  margin-bottom: 20px;
}
.org-name {
  width: 175px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1a1a1a;
}
.status {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.mobile_heading_class {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: -20px;
  margin-bottom: 15px;
}
.v-sidebar-menu.vsm_rtl {
  overflow: hidden;
}
.v-sidebar-menu .vsm--dropdown .vsm--list {
  background: transparent !important;
  transition: all 0.3s ease;
}
.v-sidebar-menu .vsm--dropdown .vsm--link {
  color: #555a5f;
  font-weight: normal;
}
.v-sidebar-menu.vsm_rtl {
  right: 0;
  padding-top: 0px;
  left: inherit;
  text-align: left;
  direction: ltr;
  z-index: 1000 !important;
}
.vsm_rtl .vsm--link {
  padding-left: 10px !important;
  color: black;
  font-weight: normal;

  /* padding-top: 10px;
  padding-bottom: 10px;
  color: #555a5f;
  font-size: 14px; */
}
.vsm_rtl .vsm--link.vsm--link_level-2 {
  color: #555a5f;
  padding: 0;
  padding-left: 65px !important;
}
.vsm_rtl .vsm--link.vsm--link_level-2::before {
  content: "-";
  text-align: left;
  float: left;
  padding-right: 5px;
  text-align: center;
}
.vsm_rtl .vsm--link.vsm--link_level-2::after {
  content: "";
  text-align: right;
  padding-right: 5px;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-top: 12px;
  margin-right: 10px;
}
.v-sidebar-menu.vsm_rtl .vsm--arrow {
  left: inherit;
  right: 10px;
}
.v-sidebar-menu.vsm_rtl .vsm--link_level-1 .vsm--icon {
  margin: auto 30px auto 0px;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: inherit;
  background-color: white;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.dashboard-icon {
  background: url("../assets/dashboard.svg") no-repeat !important;
  background-position: center !important;
  border: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.subs-icon {
  background: url("../assets/subscribers.svg") no-repeat !important;
  background-position: center !important;
  border: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.users-icon {
  background: url("../assets/user.svg") no-repeat !important;
  background-position: center !important;
  border: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.settings-icon {
  background: url("../assets/settings.svg") no-repeat !important;
  background-position: center !important;
  border: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.logout-icon {
  background: url("../assets/logout.svg") no-repeat !important;
  background-position: center !important;
  border: none;
}
.close-sidebar {
  width: 26px;
  height: 26px;
  background: transparent;
  border-radius: 15px;
  color: #333333;
  padding: 0 0px 3px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease;
}
.close-sidebar::before {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  font-size: 28px;
  margin-right: 15px;
  /* margin-left: 5px; */
}
</style>
