const CryptoJS = require("crypto-js");
const cryptSeceret = '#$%+&@' + process.env.VUE_APP_ACCESS_TOKEN_SECRET + '#$%+&@'

const encrypt = (data) => {
    // Implement your encryption logic here
    return CryptoJS.AES.encrypt(JSON.stringify(data), cryptSeceret).toString();
  };
  
  const decrypt = (encryptedData) => {
    // Implement your decryption logic here
    let bodyBytes = CryptoJS.AES.decrypt(encryptedData, cryptSeceret)
    let data = JSON.parse(bodyBytes.toString(CryptoJS.enc.Utf8))
    return data;
  };
  
  const customLocalStorage = {
    getItem: (key) => {
      const encryptedValue = localStorage.getItem(key);
      if (encryptedValue) {
        return decrypt(encryptedValue);
      }
      return null;
    },
  
    setItem: (key, value) => {
      const encryptedValue = encrypt(value);
      localStorage.setItem(key, encryptedValue);
    },

    removeItem: (key) => {
      localStorage.removeItem(key);
    },
    
    clear: () => {
      localStorage.clear();
    },
    
  };
  
  const localStoragePlugin = {
    install: (Vue) => {
      Vue.prototype.$localStorage = customLocalStorage;
    },
  };
  
  export default localStoragePlugin;
  