<template>
  <div class="row" style="z-index: 101; right: 0">
    <b-navbar :class="mainHeaderClass" class="col d-flex justify-space-between align-items-center"
      style="background: #fbfbfb">
      <b-navbar-brand class="mt-4 d-flex align-items-center"
        :class="collapsed && !isMobile ? 'collapse-class' : 'open-class'">
        <img class="mr-1" src="../assets/ijura_logo_updated.svg" style="width: 47px; height: 38px" />
        <div style="width: 2px; background-color: lightgray; height: 6vh" class="mx-2"></div>
        <img class="mr-5" style="height: 55px; padding: 8px" src="../assets/tata-logo.png" alt="logo" />

        <template>
          <div class="text-center p-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-slot role="button" class="p-2 px-3 pointer hamburger" v-on="on">
                  <img src="../assets/toggle.svg" v-bind="attrs" />
                </v-slot>
              </template>
              <v-list class="pb-0"
                v-if="userRole != 'Account_Manager' || (logedInUser.orgNum == orgNum && logedInUser.role == 'Admin')">
                <v-list-item v-for="(item, index) in menuOptions" :key="index" class="removePadding">
                  <div v-if="item.commonOptions" class="py-0 px-2 w-100">
                    <v-list-item-title class="menuItems d-flex align-items-center" style="cursor: pointer;"
                      v-for="(commonOption, i) of item.commonOptions" :key="i"
                      @click="getSelectedOption(commonOption, i)">
                      <div :class="[{ 'active-bg': commonOption.active }, 'hamburger-menu-item']">
                        <img class="py-2 pr-2" :src="require(`@/assets/${commonOption.icon}`)" alt="">
                        <span> {{ commonOption.title }}
                        </span><span :class="{ 'active-dot-blue': commonOption.active }"></span>
                      </div>
                    </v-list-item-title>
                  </div>
                  <!--  && userRole == 'Super_Admin' -->
                  <div v-if="item.superAdminOptions && userRole == 'Super_Admin'"
                    class="superAdminMenu bg-light w-100 py-0 px-3" style="cursor: pointer;">
                    <v-list-item-title class="menuItems d-flex align-items-center py-2 justify-content-center"
                      v-for="(superAdminOption, inx) of item.superAdminOptions" :key="inx"
                      @click="getSelectedSuperAdminOption(superAdminOption, inx)"> <span
                        class="hamburger-menu-item p-2 px-3">{{ superAdminOption.title
                        }}</span></v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
              <v-list lass="pb-0" v-if="userRole == 'Account_Manager' && logedInUser.orgNum != orgNum
                ">
                <v-list-item class="removePadding">
                  <div class="superAdminMenu bg-light w-100 py-0 px-3" style="cursor: pointer">
                    <v-list-item-title class="menuItems d-flex align-items-center py-2 justify-content-center"
                      v-for="(superAdminOption, inx) of accountManagerOptions" :key="inx"
                      @click="goTomanageCustomersList(superAdminOption, inx)">
                      <span>{{
                        superAdminOption.title
                      }}</span></v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </b-navbar-brand>

      <b-navbar-brand class="mt-4 d-flex align-items-center"
        :class="collapsed && !isMobile ? 'collapse-class' : 'open-class'" style="margin-right: 70px">
        <div class="orgDetails d-flex mr-5">
          <!-- <div>
            <img class="mr-1" height="55px" :src="orgLogo" />
          </div> -->
          <div class="d-flex flex-column mx-2 justify-content-center" style="font-size: 14px">
            <span>{{ orgName }}</span>
            <span class="status">
              <span
                class="active-dot"
                v-if="orgStatus == true || orgStatus == 'true'"
              ></span>
              <span class="inactive-dot" v-else></span>
              <span v-if="orgStatus == true || orgStatus == 'true'"
                >Active</span
              >
              <span v-else>Inactive</span>
            </span>
          </div>
        </div>
        <div class="profileSetting d-flex align-items-center">
          <template>
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div class="userLogo hover d-flex align-items-center justify-content-center" v-on="on">
                    <span v-bind="attrs" class="user-logo-size text-dark ">{{
                      getUserLogo(userName)
                    }}
                    </span>
                  </div>
                </template>
                <v-list style="width: 211px !important">
                  <v-list-item>
                    <div class="d-flex">
                      <div class="userLogo d-flex align-items-center justify-content-center">
                        <span class="user-logo-size text-dark">{{ getUserLogo(userName) }}
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column mx-2 justify-content-center"
                      >
                        <span>{{ userName }}</span>
                        <span class="status" style="color: #a3a3a3 !important">
                          {{ getRoleName(userRole) }}
                        </span>
                      </div>
                    </div>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item style="min-height: 0px !important; display: block">
                    <!-- <div>
                     
                      <img class="px-2 py-2 mr-2" style="cursor:pointer" src="../assets/settings.svg" alt="settings"
                        v-if="userRole == 'Admin'" /> <span style="cursor:pointer">Settings</span>
                    </div> -->
                    <div @click="signOut" class="logout">
                      <img class="px-2 py-3 mr-2" style="cursor:pointer" src="../assets/logout.svg" alt="logout" />
                      <span style="cursor:pointer">Logout</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </div>
      </b-navbar-brand>
    </b-navbar>
    <PopupAlert v-model="signOutAlert" :isSignOut="true" />
    <PopupAlert v-model="changeOrg.showAlert" :changeOrg="true" :organizationId="selected" :alertMsg="changeOrg.alertMsg"
      :addMoreText="changeOrg.addMoreText" :nextText="changeOrg.nextText" @onAdd="changeOrganizations"
      :isConfirmation="true">
    </PopupAlert>
  </div>
</template>
<script>
// import Avatar from "@/widgets/Avatar";

import PopupAlert from "../widgets/PopupAlert";
import { getAllCustomers } from "../services/CustomerService";
// import { isLogedInUser } from "../services/CommonService";
import { accessTokenOrgDetails, getUserRole } from "../services/CommonService";

export default {
  components: {
    // "w-avatar": Avatar
    PopupAlert,
  },
  props: {
    mainHeaderClass: {
      type: String,
      default: "",
    },
    selectedPage: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logedInUser: {},
      orgNum: "",
      orgName: "",
      // isAccountmanagerLogedIn: false,
      changeOrg: {
        showAlert: false,
        alertMsg: "Are you want to  change the customer ?",
        data: "",
        addMoreText: "CHANGE",
        nextText: "CANCEL",
      },
      orgData: [],
      selected: "Diatoz",
      options: [],
      currentPage: "Dashboard",
      navbarList: [
        {
          label: "DashBoard",
          iconName: "Dashboard",
          to: "/dashboard",
        },
      ],
      activeTab: 1,
      userName: "",
      userRole: "",
      orgStatus: Boolean,
      status: "",
      orgName: "",
      signOutAlert: false,
      orgLogo: "",
      menuOptions: [
        {
          commonOptions: [
            {
              title: "Dashboard",
              path: "/dashboard",
              icon: "menuDashboard.svg",
              active: true,
            },
            // { title: 'Manage Devices', icon: 'menuManageDevice.svg', active: false },
            {
              title: "Manage Users",
              path: "/manage-users",
              icon: "menuManageUser.svg",
              active: false,
            },
            {
              title: "List Devices",
              path: "/devices",
              icon: "menuManageDevice.svg",
              active: false,
            },
          ],
        },
        {
          superAdminOptions: [{ title: "Manage Customer", color: "" }],
        },
      ],
      accountManagerOptions: [{ title: "Go To Customers", color: "" }],
      userRoleOptions: [
        { value: "Admin", text: "Admin" },
        { value: "Tech_support", text: "Tech Support" },
        { value: "Sales_support", text: "Sales Support" },
        { value: "Account_Manager", text: "Account Manager" },
        { value: "Super_Admin", text: "Super Admin" },
      ],
    };
  },
  watch: {
    selectedPage: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage);
    },
  },
  created() {
    this.userName = this.$localStorage.getItem("userName");
    this.orgNum = this.$localStorage.getItem("orgNum");
    this.orgName = this.$localStorage.orgName ? this.$localStorage.getItem("orgName") : "";
    this.logedInUser = accessTokenOrgDetails();
    this.userRole = getUserRole();
    this.getAllCustomers();
    this.menuOptions[0].commonOptions.map((options) => {
      this.$router.currentRoute.path == options.path
        ? (options.active = true)
        : (options.active = false);
    });
    if (this.orgName === 'Ijura') {
      this.menuOptions[0].commonOptions = this.menuOptions[0].commonOptions.filter(({ title }) => title !== 'List Devices')
    }

  },
  methods: {
    getRoleName(roleValue) {
      return this.userRoleOptions.filter((x) => x.value == roleValue)[0].text;
    },
    getAllCustomers() {
      getAllCustomers("Customer", 0, 500)
        .then((res) => {
          if (res.success) {
            let result = res.response.items.filter(
              (x) => x.id === this.$localStorage.getItem("orgNum")
            );
            this.orgName = result[0].customer_name;
            this.orgStatus = result[0].status;
            this.status = result[0].status;
            this.orgLogo = result[0].logo;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeOrganization() {
      console.log(this.selected);
      this.changeOrg.showAlert = true;
    },
    goToManageCustomerPage() {
      // this.$router.push("/customer-profile");
      this.$router.push("/manage-customer");
    },
    changeOrganizations() {
      this.$router.push("/manage-customers");
    },
    getMenuIconUrl(iconName) {
      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + iconName + ".svg");
    },
    toggleSideBar() {
      this.$emit("getSelectedMenuEvent");
    },
    openSettings() {
      console.log("open setttings");
      // if (this.$route.name != "Settings") {
      //   this.$router.push({ path: "/settings" });
      // }
      this.$router.push("/manage-customer");
    },
    signOut() {
      console.log("signOut called");
      // sessionStorage.clear();
      // this.$localStorage.clear();
      // // this.$store.commit("logOut");
      // this.$router.push("/login");
      this.signOutAlert = true;
    },
    getUserLogo(userName) {
      let splitedName = userName.split(" ");
      if (splitedName.length == 1) {
        return splitedName[0][0];
      } else if (splitedName.length >= 2) {
        return splitedName[0][0] + splitedName[splitedName.length - 1][0];
      }
    },
    getSelectedOption(option, index) {
      this.menuOptions[0].commonOptions.map((options, i) => {
        index == i ? (options.active = true) : (options.active = false);
      });
      switch (index) {
        case 0:
          this.$router.push("/dashboard");
          break;
        // case 1:
        //   this.$router.push("/manage-devices");
        //   break;
        case 1:
          this.$router.push("/manage-users");
          break;
        case 2:
          this.$router.push("/devices");
          break;
      }
      console.log(option, index);
    },
    goTomanageCustomersList(option, index) {
      this.$router.push("/manage-customers");
    },
    getSelectedSuperAdminOption(option, index) {
      this.$router.push("/manage-customers");
    }
  },
};
</script>
<style scoped>
.hamburger {
  border-radius: 5px;
}

.hamburger:hover {
  background: #4c8fca40;
}

::v-deep .removePadding {
  padding: 0;
  min-height: 0 !important;
}

::v-deep .v-divider {
  margin-bottom: 0.3rem !important;
}

/* #list-item-34 {
  background-color: #F6F6F6;
} */

.active-dot-blue {
  margin-left: 5px;
  height: 8px;
  width: 8px;
  background-color: #3d86c6;
  border-radius: 50%;
  display: inline-block;
}

.hamburger-menu-item {
  border-radius: 5px;
  padding: 0 5px;
  width: 100%;
  margin-bottom: 3px;
}

.hamburger-menu-item:hover {
  background: #4c8fca1c;
}

.active-bg {
  background: #4c8fca40;
}

.menuItems:hover {
  color: #3d86c6;
}

.boldColor {
  color: #1b1c1d !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.userLogo {
  border-radius: 50%;
  background-color: #e5e5e5;
  width: 46px;
  height: 46px;
  /* border-bottom: 1px solid #D1D1D1; */
}
.userLogo.hover {
  cursor:pointer;
}
.userLogo.hover:hover {
  background: #4c8fca40;
}

.userLogo.hover {
  cursor: pointer;
}

.userLogo.hover:hover {
  background: #4c8fca40;
}

.user-logo-size {
  justify-content: center;
  align-items: center;
  display: flex;
  /* font-size: 26px; */
  width: 31px;
  height: 32px;
}

.logout {
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.logout:hover {
  background: #4c8fca1c;
}

.collapse-class {
  transition: all 0.2s ease-in;
  margin-left: 20px;
}

.open-class {
  transition: all 0.2s ease-out;
  margin-left: 70px;
}

.status {
  font-family: "Lato", sans-serif !important;
  font-size: 12px;
  text-align: left;
  color: #363636;
}

.org-name {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #1a1a1a;
}

.userName {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  text-align: left;
  color: #292929;
}

.avatar-img {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
}

.options {
  margin-top: 20px;
  display: flex;
}

.navbar-expand .navbar-nav {
  padding-left: 0px !important;
}

.nav-link {
  padding: 1rem 0rem;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  border: #10161b 1px solid;
  background: #10161b;
  color: white;
  min-width: 150px;
  text-align: center;
  border-bottom: 3px solid #f47d35;
}

.navbar-light .navbar-nav .nav-link {
  min-width: 150px;
  text-align: center;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active:hover {
  border: #10161b 1px solid !important;
  background: #10161b !important;
  color: white !important;
  min-width: 150px !important;
  text-align: center !important;
}

.navbar {
  padding: 0rem;
  margin-top: -10px;
}

p.heading {
  padding: 1.1rem 1rem 0;
  margin: 0;
  font-family: "Lato", sans-serif !important;
  font-size: 11px;
  color: #ffffff;
}

.roleClass {
  font-family: "Lato", sans-serif !important;
  font-size: 12px;
  text-align: left;
  color: #a3a3a3;
}

.toggleButton {
  margin-left: 60px;
}

.mobileToggleButton {
  margin-left: auto;
  margin-right: 15px;
  margin-top: 15px;
}

.select-box {
  width: 150px;
  margin-top: 7px;
  height: 30px;
  margin-bottom: 0px !important;
  border: none;
}

.select-box:focus {
  border: none !important;
  box-shadow: none !important;
}
</style>
