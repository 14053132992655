import { getUserRole } from "./CommonService";
import {
    ApiHelper
} from "./Helper/ApiHelper";
import Vue from 'vue';
const apiHelper = new ApiHelper();


export const getAllCustomers = (type, currentPage, perPage) => {
    let userRole = getUserRole();
    let userId = Vue.prototype.$localStorage.getItem("userId");
    const orgNum = Vue.prototype.$localStorage.getItem('orgNum');
    const uri = `/customer/?orgNum=${orgNum}&userId=${userId}&role=${userRole}&type=${type}&currentPage=${currentPage}&pageSize=${perPage}`;
    return apiHelper.get(uri);
}
export const deleteOrganiztion = () => {
    const orgNum = Vue.prototype.$localStorage.getItem('orgNum') || '1';
    const uri = `/organization/delete`;
    return apiHelper.post(uri, { orgNum });
}