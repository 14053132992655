import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';

// import { registration } from './modules/registration'
// import { user } from './modules/user'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        org: {},
        portalUser: {},
        domains: []
    },
    getters: {
        getOrg: state => state.org,
        portalUser: state => state.portalUser,
        getDomains: state => state.domains
    },
    mutations: {
        setPortalUser(state, payload) {
            state.portalUser = payload;
        },
        setOrg(state, payload) {
            state.org.name = payload.name;
            state.org.address1 = payload.address1;
            state.org.address2 = payload.address2;
            state.org.city = payload.city;
            state.org.state = payload.state;
            state.org.country = payload.country;
            state.org.zipCode = payload.zipCode;
            state.org.logo = payload.logo
        },
        setUseCase(state, payload) {
            state.org.regType = payload.regType;
            state.org.profileType = payload.profileType;
            state.org.networkCoverage = payload.networkCoverage;
            state.org.planQuantity = payload.planQuantity;
            state.org.hasDomain = payload.hasDomain
        },
        setPlan(state, payload) {
            state.org.planId = payload;
        },
        setDomains(state, payload) {
            state.domains = [];
            state.domains = payload.map(domain => domain.domainName);
        },
        setOrgMessage(state, payload){
            state.org.message = payload;
        },
        resetStore(state){            
            state.org = {};
            state.portalUser = {};
            state.domains = [];
        }
    }
    // plugins: [new VuexPersistence().plugin]
});