import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import { store } from "./store/store";
import BootstrapVue from "bootstrap-vue";
import vuetify from './plugins/vuetify'
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import { VueReCaptcha } from "vue-recaptcha-v3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vueCountryRegionSelect from "vue-country-region-select";
import "../src/config/Validation.js";
import * as VueGoogleMaps from "vue2-google-maps";
import NoSidebar from "./layouts/NoSidebar.vue";
import Sidebar from "./layouts/Sidebar.vue";
// import "material-design-icons-iconfont/dist/material-design-icons.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPencilAlt,
  faEye,
  faPlusCircle,
  faMinusCircle,
  faInfoCircle,
  faCircleNotch,
  faSignOutAlt,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faPencilAlt,
  faEye,
  faPlusCircle,
  faMinusCircle,
  faInfoCircle,
  faCircleNotch,
  faSignOutAlt,
  faAngleRight
);
import { dom } from "@fortawesome/fontawesome-svg-core";
import localStoragePlugin from './plugins/localStoragePlugin';
import axios from 'axios'

// Set up global Axios configuration for sending credentials (cookies)
axios.defaults.withCredentials = true;

Vue.use(localStoragePlugin);
dom.watch();
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(vueCountryRegionSelect);
Vue.component("ValidationObserver", ValidationObserver);


Vue.use(VueReCaptcha, {
  siteKey: "6LcuRdYUAAAAAFA304LGXEJsMSj3IPou7r3zSB8i",
  loaderOptions: {
    useRecaptchaNet: true
  }
});
Vue.component("ValidationProvider", ValidationProvider);
// Sentry.init({
//   dsn: 'https://4290c59f20904047820bfb3108078709@sentry.io/1913633',
//   integrations: [new Integrations.Vue({
//     Vue,
//     attachProps: true
//   })],
// });

// Vue.userType = Vue.prototype.userType = "Enterprise";


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAvc4MorLAvV4gG2zUsi-injvNitDOW-Dc",
    libraries: "places", // necessary for places input
    region: "IN",
    language: "en",
    callback: "initEditClntInfoAutoComplete",
    type: "regions"
  }
});
Vue.use(BootstrapVue);

const globalOptions = {
  dropdownOptions: {
    showDialCodeInList: false,
    showFlags: true,
    showDialCodeInSelection: true,
    tabindex: 0
  },
  autoFormat: false,
  autoDefaultCountry: false,
  defaultCountry: 'SG',
  inputOptions: {
    showDialCode: false,
    tabindex: 0
  }
};
Vue.use(VueTelInput, globalOptions);

Vue.component("no-sidebar-layout", NoSidebar);
Vue.component("sidebar-layout", Sidebar);
Vue.config.silent = true;
Vue.config.productionTip = false;

// Initialize the application version
Vue.prototype.$currentVersion = '1.0.1';
const savedVersion = Vue.prototype.$localStorage.getItem('appVersion');
if (savedVersion !== Vue.prototype.$currentVersion) {
  // Versions don't match, clear Vue.prototype.$localStorage
  Vue.prototype.$localStorage.clear();
  Vue.prototype.$localStorage.setItem('appVersion', Vue.prototype.$currentVersion);
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  mounted() {
    // Initialize last activity time
    localStorage.setItem('lastActivityTime', Date.now().toString())
    // Update last activity time on user interaction
    window.addEventListener('mousemove', this.updateLastActivityTime)
    window.addEventListener('keydown', this.updateLastActivityTime)
    setInterval(() => {
      const lastActivityTime = localStorage.getItem('lastActivityTime')
      const currentTime = new Date().getTime()
      const idleTime = 60 * (60 * 1000) //60 min in miliseconds
      if (!lastActivityTime || (currentTime - parseInt(lastActivityTime)) > idleTime) {
        localStorage.setItem('idleStatus', 'TIIMEOUTYES')
      } else {
        localStorage.setItem('idleStatus', 'TIIMEOUTNO')
      }
      const allTabsIdle = !Object.values(localStorage).some(val => val === 'TIIMEOUTNO')
      if (allTabsIdle && (this.$route.path !== '/' && this.$route.path !== '/login' && this.$route.path !== '/pre-register' && this.$route.path !== '/pre-registration-verify' && this.$route.path !== '/verify-flow'
        && this.$route.path !== '/registration-verified' && this.$route.path !== '/register' && this.$route.path !== '/verify-email-otp' && this.$route.path !== '/verify-phone-otp')) {
        // If screen is idle for all tabs then do logout
        sessionStorage.clear();
        Vue.prototype.$localStorage.clear();
        router.push("/login").catch(() => { });
      }
    }, 10000)
  },
  methods: {
    updateLastActivityTime() {
      localStorage.setItem('lastActivityTime', Date.now().toString())
    }
  }
}).$mount("#app");
